$(document).ready(function() {

    if ($('.map-div').length) {

        google.maps.event.addDomListener(window, 'load', map_init);
    }
    function map_init() {

        var browser_size = $( window ).width();

        if (browser_size > 760) {
            var zoom_set = 7;
            var drag = true;
            var scroll_wheel = true;

        }
        else {
            var zoom_set = 9;
            var drag = false;
            var scroll_wheel = false;
        }

        var acLatlng = new google.maps.LatLng(46.065271,6.551612);

        var mapOptions = {

            zoom: zoom_set,
            panControl: false,
            zoomControl: true,
            mapTypeControl: false,
            scrollwheel: scroll_wheel,
            streetViewControl: true,
            center: acLatlng,
            draggable: drag,

        };

        var mapElement = document.getElementById('map-div');
        var map = new google.maps.Map(mapElement, mapOptions);

        var contentString = '<h4><img src="/img/dtt_icon_icon.png">&nbsp;&nbsp;DT Technologies France</h4> 220 Avenue des Lacs, BP150<br>74954 Scionzier Cedex - France';

        var infowindow = new google.maps.InfoWindow({
            content: contentString
        });


        var marker = new google.maps.Marker({

            position: acLatlng,
            map: map
           // icon: base_url + 'img/map_marker.png'

        });

        marker.addListener('click', function() {
            infowindow.open(map, marker);
            map.setZoom(10);
            map.setCenter(marker.getPosition());
        });

    }



});

var image = { width: 1920, height: 968 };

var hometitletarget = { x: 440, y: 220 };
var hometitle = $('.title-container');

var hometexttarget = {x: 1435, y: 350};
var hometext = $('.home-text-container');

$(window).resize(function() {
    updateBgText(hometitle, hometitletarget, 1.7);
    updateBgText(hometext, hometexttarget, 1);
    textOutsideWindow(hometext);
});

$(document).ready(function() {
    updateBgText(hometitle, hometitletarget, 1.7);
    updateBgText(hometext, hometexttarget, 1);
    textOutsideWindow(hometext);
});

function updateBgText(element, target, fontsize) {
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();

    // Get largest dimension increase
    var xScale = windowWidth / image.width;
    var yScale = windowHeight / image.height;
    var scale;
    var yOffset = 0;
    var xOffset = 0;



    if (xScale > yScale) {
        // The image fits perfectly in x axis, stretched in y

        scale = xScale;

        yOffset = 1;

    } else {
        // The image fits perfectly in y axis, stretched in x

        scale = yScale;

        xOffset = (windowWidth - (image.width * scale)) / 2;
    }


    element.css('top', (target.y) * scale + yOffset);
    element.css('left', (target.x) * scale + xOffset);
    element.css('font-size', scale * fontsize + 'em');
}

function textOutsideWindow(element) {

    if (element.length) {



        var elPosition = element.position();
        var elRightPosition = elPosition.left + element.width();
        var windowWidth = $(window).width();
        if (elRightPosition > windowWidth) {
            $('.home-text-container').addClass('opacity-hidden');
            $('.home-text-mobile-container').removeClass('hidden');
        } else {
            $('.home-text-container').removeClass('opacity-hidden');
            $('.home-text-mobile-container').addClass('hidden');
        }
    }


}